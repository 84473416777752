import React, { useEffect } from "react";
import { Row, Col, Button } from "reactstrap";
import { submit } from "../styles/jiraissue.module.css";

const ELEMENT_ID = "jira-feedback-button";
const WINDOW_VAR_NAME = "jiraIssueCollector";

const JIRAIssueCollector = ({ srcUrl, Name }) => {
  useEffect(() => {
    window["ATL_JQ_PAGE_PROPS"] = {
      triggerFunction: function (showCollectorDialog) {
        document.getElementById(ELEMENT_ID).addEventListener("click", (e) => {
          console.log("triggerFunction");
          e.preventDefault();
          showCollectorDialog();
        });
      },
    };

    const setCollector = () => {
      const appElement = document.querySelector("body");
      if (appElement) {
        console.log("loading issue collector");
        const snippet = document.createElement("script");
        snippet.type = "text/javascript";
        snippet.src = srcUrl;
        appElement.appendChild(snippet);
      }
    };

    if (!window[WINDOW_VAR_NAME]) {
      setCollector();
      window[WINDOW_VAR_NAME] = this;
    }
  }, []);

  return (
    <Row>
      <Col>
        <Button className={submit} href="#" color="primary" id={ELEMENT_ID}>
          {Name}
        </Button>
      </Col>
    </Row>
  );
};

export default JIRAIssueCollector;
